import React from 'react';
import Layout from '../components/Layout';
import ModelCanvas from '../components/ModelCanvas';
import Button from '../components/Button';

export default function indexPage() {
  const isBrowser = typeof window !== 'undefined';

  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Ascending Aortic Aneurysm</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner model">
            <section>
              <div className="row">
                <div className="col-6 col-12-medium">
                  <h2>Ascending Aortic Aneurysm</h2>
                  <p>
                    is an enlargement (dilatation) of the ascending aorta.{' '}
                    <br />
                    <br />
                    <i>
                      "By definition, an aneurysm is a localized or diffuse
                      dilation of an artery with a diameter at least 50% greater
                      than the normal size of the artery."
                    </i>
                    <br />
                    <br />
                    Aortic aneurysm cause weakness of the aortic wall and
                    increase the risk of rupture - when untreated immediately,
                    results in shock and death. <br />
                    Conservative therapy of aortic aneurysms is based on strict
                    blood pressure control to prevent further expansion of the
                    aneurysm. The definitive treatment may be endovascular
                    repair or open heart surgery with replacement of the part of
                    aorta with graft.
                  </p>
                </div>
                {isBrowser && (
                  <div className="col-6 col-12-medium">
                    <ModelCanvas
                      model_path={'/aneurysm-of-ascending-aorta.glb'}
                      camera_position={[5, 5, 5]}
                      light_intensity={0.3}
                      group_position={[0.3, -0.9, 0.4]}
                      group_rotation={[0, 0, 0]}
                      group_scale={[11, 11, 11]}
                    />
                  </div>
                )}
              </div>
            </section>
            <div>
              <br />
              <ul className="actions stacked">
                <li>
                  <br />
                  <Button
                    buttonClass="button small primary"
                    url="https://biovoxel.tech/en/services/organ-models/"
                    text="Print 3D Model"
                    action="print"
                  />{' '}
                  <Button
                    buttonClass="button icon solid fa-coffee"
                    url="https://buymeacoff.ee/3DHearter"
                    text="^^"
                    action="donate"
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
}
